<template>
  <v-container fluid>
    <v-row
      class="mx-auto"
      v-for="category in categories"
      :key="category.name"
    >
      <v-col cols="12">
        <h2>{{ category.name }}</h2>
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(item, _key) in category.itens"
        :key="_key"
      >
        <v-hover v-slot:default="{ hover }">
          <a
            :href="item.html_link"
            style="text-decoration: none"
          >
            <v-card
              :id="_key"
              class="mx-auto my-auto px-2 d-flex"
              width="352px"
              height="100px"
              :color="hover ? 'grey lighten-2' : 'grey lighten-3'"
              elevation="1"
              :to="item.link"
              :disabled="item.disabled"
            >
              <v-row
                no-gutters
                align="center"
                justify="center"
              >
                <v-col cols="2">
                  <v-icon
                    class="my-auto d-flex justify-center"
                    :size="item.icon_size || 40"
                    color="primary"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-col>
                <v-col cols="10">
                  <v-card-text class="mx-auto my-auto">
                    <div
                      class="subtitle-2"
                      style="word-break: normal"
                    >
                      {{ item.name }}
                    </div>
                    <div class="caption text--secondary">
                      {{ item.description }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </a>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [profilePermissionsMixin],
  data: () => ({
    categories: []
  }),
  mounted() {
    this.setCategories();
  },
  methods: {
    setCategories() {
      if (
        this.accessReleased('LAYERS_PERSONALIZADOS_HISTORICO') ||
        this.accessReleased('CANVAS_HISTORICO')
      ) {
        this.categories.push({
          name: 'Criação de Mapas',
          itens: []
        });
      }

      if (this.accessReleased('LAYERS_PERSONALIZADOS_HISTORICO')) {
        this.categories[0].itens.push({
          name: 'Crie layers (camadas)',
          link: 'mapas/layers-personalizados/historico',
          html_link: null,
          description:
            'Efetue consultas nos dados geográficos e crie layers para serem plotados em mapas',
          icon: 'mdi-map-marker-plus-outline'
        });
      }

      if (this.accessReleased('CANVAS_HISTORICO')) {
        this.categories[0].itens.push({
          name: 'Mapas Personalizados',
          link: 'mapas/canvas/historico',
          html_link: null,
          description:
            'Monte mapas plotando layers criados com base em dados geográficos',
          icon: 'mdi-map-plus'
        });
      }

      if (
        this.accessReleased('ENTIDADES_GEOGRAFICAS_DA_BDGD_VISUALIZAR_MAPA') ||
        this.accessReleased('ENTIDADES_GEOGRAFICAS_COMPARAR_NO_MAPA')
      ) {
        this.categories.push({
          name: 'Mapas de Entidades Geográficas',
          itens: []
        });
      }

      if (
        this.accessReleased('ENTIDADES_GEOGRAFICAS_DA_BDGD_VISUALIZAR_MAPA')
      ) {
        this.categories[1].itens.push({
          name: 'Entidades Geográficas da BDGD',
          link: 'mapas/entidades-geo-bdgd/visualizar-mapa',
          html_link: null,
          description:
            'Visualize as entidades geográficas da BDGD plotadas como imagens',
          icon: 'mdi-map-search'
        });
      }

      if (this.accessReleased('ENTIDADES_GEOGRAFICAS_COMPARAR_NO_MAPA')) {
        this.categories[1].itens.push({
          name: 'Comparar Entidades Geográficas',
          link: 'mapas/entidades-geo-bdgd/comparar-versoes-no-mapa',
          html_link: null,
          description:
            'Compare no mapa versões diferentes de entidades geográficas da BDGD',
          icon: 'mdi-map-marker-distance'
        });
      }

      if (
        this.accessReleased('SETORIZACOES_HISTORICO') ||
        this.accessReleased('ANALISES_SETORIZADAS_HISTORICO')
      ) {
        this.categories.push({
          name: 'Análises Setorizadas',
          itens: []
        });
      }

      if (this.accessReleased('SETORIZACOES_HISTORICO')) {
        this.categories[2].itens.push({
          name: 'Crie setores',
          link: 'mapas/setorizacoes/historico',
          html_link: null,
          description:
            'Divida a área de concessão em setores para uso em Análises Setorizadas',
          icon: 'mdi-chart-bubble'
        });
      }

      if (this.accessReleased('ANALISES_SETORIZADAS_HISTORICO')) {
        this.categories[2].itens.push({
          name: 'Análises Setorizadas',
          link: 'mapas/analises-setorizadas/historico',
          html_link: null,
          description:
            'Crie mapas de categorização, intensidade e cor a partir de setorizações',
          icon: 'mdi-chart-scatter-plot'
        });
      }
    }
  }
};
</script>
